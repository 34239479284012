import React, { useState, useEffect, useRef, useCallback } from 'react';
import api from '../../../api';
import './EventManagement.css';
import LoadingBar from '../../Ui/LoadingBar/LoadingBar';
import { processMultipleImages } from '../../../utils/imageprocessing';
import ReactMarkdown from 'react-markdown';
import rehypeSanitize from 'rehype-sanitize';
import DOMPurify from 'dompurify';
import EventFinancials from './EventFinancials';
import ReferralSystem from './ReferralSystem';
import TicketTypeForm from './TicketTypeForm';
import TicketTypeDisplay from './TicketTypeDisplay';
import CityManagement from './CityManagement';
import EventFilter from '../../Events/EventFilter';
import FreeTicketsManager from './FreeTicketsManager';


const customSchema = {
  tagNames: ['h1', 'h2', 'h3', 'p', 'strong', 'em', 'a', 'ul', 'ol', 'li', 'hr'],
  attributes: {
    a: ['href'],
    '*': ['className']
  },
  allowComments: false,
  allowElements: ['h1', 'h2', 'h3', 'p', 'strong', 'em', 'a', 'ul', 'ol', 'li', 'hr'],
  protocols: {
    href: ['http', 'https', 'mailto']
  },
  transformUrls: {
    '*': (url) => {
      return url;
    }
  }
};


/**
 * Formats a date-time string for input fields.
 *
 * @param {string} dateTimeString - The date-time string to format.
 * @returns {string} Formatted date-time string.
 */
const formatDateTimeForInput = (dateTimeString) => {
  return dateTimeString.slice(0, 16); // This will return the date string in the format "YYYY-MM-DDTHH:mm"
};

/**
 * Manages event creation, editing, and referral code generation.
 *
 * @component
 * @returns {React.ReactElement} Event management interface.
 */
const EventManagement = () => {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: '',
    description: '',
    shortDescription: '',
    dateTime: '',
    endDateTime: '', // Add this line
    location: '',
    vendor: '',
    ticketTypes: [{ title: '', description: '', badge: '', price: '', quantity: 0, reservedTickets: 0 }],
    priceRange: '',
    lastEntry: '',
    ageRestriction: '',
    isFeatured: false,
    ticketSalesCutoff: '',
  });
  const [editingEvent, setEditingEvent] = useState(null);
  const [eventImages, setEventImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const fileInputRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ticketCounts, setTicketCounts] = useState({});
  const [showFinancials, setShowFinancials] = useState({});
  const [showReferrals, setShowReferrals] = useState({});
  const formRef = useRef(null);
  const [cities, setCities] = useState([]);
  const [selectedFilterCity, setSelectedFilterCity] = useState('');
  const [showFreeTickets, setShowFreeTickets] = useState({});

  useEffect(() => {
    fetchEvents();
    fetchCities();
  }, []);

  useEffect(() => {
    console.log('Cities state updated:', cities); // Add this line
  }, [cities]);

  useEffect(() => {
    return () => {
      previewImages.forEach(URL.revokeObjectURL);
    };
  }, [previewImages]);

  const fetchEvents = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.getAllEvents();

      // Sanitize the description field
      response.data.forEach(event => {
        event.description = DOMPurify.sanitize(event.description);
      });
      
      console.log('Fetched events:', response.data);
      setEvents(Array.isArray(response.data) ? response.data : []);
      
      // Fetch ticket counts for each event
      response.data.forEach(event => {
        fetchTicketCount(event._id);
      });
    } catch (error) {
      console.error('Error fetching events:', error);
      setEvents([]);
      setError('Failed to fetch events. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTicketCount = async (eventId) => {
    try {
      const response = await api.getEventTicketCount(eventId);
      setTicketCounts(prevCounts => ({
        ...prevCounts,
        [eventId]: response.data
      }));
    } catch (error) {
      console.error('Error fetching ticket count:', error);
    }
  };

  const fetchCities = async () => {
    try {
      const response = await api.getAllCities();
      setCities(Array.isArray(response.data) ? response.data : []); // Ensure cities is an array
    } catch (error) {
      console.error('Error fetching cities:', error);
      setCities([]); // Set cities to an empty array on error
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('ticketTypes.')) {
      const [_, field] = name.split('.');
      setNewEvent(prev => ({
        ...prev,
        ticketTypes: prev.ticketTypes.map((ticket, i) => 
          i === index ? { ...ticket, [field]: value } : ticket
        )
      }));
    } else {
      setNewEvent(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };

  const addTicketType = () => {
    setNewEvent({
      ...newEvent,
      ticketTypes: [...newEvent.ticketTypes, { title: '', description: '', price: '', quantity: 0, color: '#FFFFFF', reservedTickets: 0, badge: '' }]
    });
  };

  const confirmDeletion = useCallback((itemType) => {
    const confirmationPhrase = 'I am sure';
    let warningMessage = `Warning: Deleting this ${itemType} cannot be undone.`;
    

      warningMessage += ` Deleting ${itemType} can be damaging to the business and users. ` +
        'It may result in loss of revenue, user trust, and potential legal issues. Please ask Chris for confirmation.';

    
    warningMessage += `\n\nIf you understand the consequences and still want to proceed, ` +
      `type "${confirmationPhrase}" to confirm deletion:`;
  
    const userInput = prompt(warningMessage);
    return userInput === confirmationPhrase;
  }, []);

  const removeTicketType = (index) => {
    if (confirmDeletion('ticket type')) {
      const updatedTicketTypes = newEvent.ticketTypes.filter((_, i) => i !== index);
      setNewEvent({ ...newEvent, ticketTypes: updatedTicketTypes });
    }
  };

  const handleFileChange = useCallback(async (e) => {
    const files = Array.from(e.target.files);
    try {
      const processedFiles = await processMultipleImages(files);
      setEventImages(processedFiles);
      setPreviewImages(processedFiles.map(file => URL.createObjectURL(file)));
    } catch (error) {
      console.error('Error processing images:', error);
      setUploadError('Error processing images. Please try again.');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploadProgress(0);
    setUploadError('');

    try {
      console.log('Submitting event with data:', newEvent); // Add this line
      const formData = new FormData();
      Object.keys(newEvent).forEach(key => {
        if (key === 'ticketTypes') {
          formData.append(key, JSON.stringify(newEvent[key]));
        } else if (key === 'dateTime' || key === 'endDateTime' || key === 'ticketSalesCutoff') {
          // Send the date string as-is, without any conversion
          formData.append(key, newEvent[key]);
        } else if (key === 'referralCodes') {
          // Don't append referralCodes to formData
        } else {
          formData.append(key, newEvent[key]);
        }
      });

      // Ensure these fields are not empty
      if (!newEvent.priceRange || !newEvent.lastEntry || !newEvent.ageRestriction) {
        throw new Error('Price Range, Last Entry, and Age Restriction are required fields');
      }

      eventImages.forEach((image, index) => {
        formData.append('images', image);
      });

      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      };

      let response;
      if (editingEvent) {
        response = await api.updateEvent(editingEvent._id, formData, config);
      } else {
        response = await api.createEvent(formData, config);
      }

      // Update the events list
      setEvents(prevEvents => {
        const updatedEvents = prevEvents.map(event => 
          event._id === response.data._id ? response.data : 
          (response.data.isFeatured ? { ...event, isFeatured: false } : event)
        );
        if (!editingEvent) {
          updatedEvents.push(response.data);
        }
        return updatedEvents;
      });

      resetForm();
      setUploadProgress(0);
    } catch (error) {
      console.error('Error submitting event:', error);
      if (error.response && error.response.data) {
        setUploadError(error.response.data.message || 'An error occurred while submitting the event.');
      } else {
        setUploadError('An error occurred while submitting the event. Please try again.');
      }
    } finally {
      setUploadProgress(0);
    }
  };

  const handleEditEvent = (event) => {
    setEditingEvent(event);
    setNewEvent({
      ...event,
      dateTime: event.dateTime.slice(0, 16), // Format the date string for the input
      ticketSalesCutoff: event.ticketSalesCutoff ? event.ticketSalesCutoff.slice(0, 16) : '',
      ticketTypes: event.ticketTypes.map(ticket => ({
        ...ticket,
        price: ticket.price.toString(),
        quantity: ticket.quantity.toString(),
        reservedTickets: ticket.sold.toString()
      }))
    });
    setPreviewImages(event.images);
    setEventImages([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    
    // Scroll to the top of the form
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  const resetForm = () => {
    setEditingEvent(null);
    setNewEvent({
      title: '',
      description: '',
      shortDescription: '',
      dateTime: '',
      endDateTime: '', // Add this line
      location: '',
      vendor: '',
      ticketTypes: [{ title: '', description: '', price: '', quantity: 0, reservedTickets: 0, badge: '' }],
      priceRange: '',
      lastEntry: '',
      ageRestriction: '',
      isFeatured: false,
      ticketSalesCutoff: '',
    });
    setEventImages([]);
    setPreviewImages([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleReferralUpdate = (eventId, updatedReferralCodes) => {
    setEvents(prevEvents => prevEvents.map(event =>
      event._id === eventId ? { ...event, referralCodes: updatedReferralCodes } : event
    ));
  };

  const handleDeleteEvent = async (eventId) => {
    if (confirmDeletion('event')) {
      try {
        await api.deleteEvent(eventId);
        setEvents(events.filter(event => event._id !== eventId));
        setUploadError('');
      } catch (error) {
        console.error('Error deleting event:', error);
        setUploadError('Failed to delete event. Please try again.');
      }
    }
  };

  const handleUpdateEventStatuses = async () => {
    try {
      const response = await api.updateEventStatuses();
      alert(response.data.message);
      fetchEvents(); // Refresh the events list after updating statuses
    } catch (error) {
      console.error('Error updating event statuses:', error);
      alert('Failed to update event statuses. Please try again.');
    }
  };

  const handleFilterCityChange = (e) => {
    setSelectedFilterCity(e.target.value);
  };

  const handleCityAdded = (newCity) => {
    setCities(prevCities => [...prevCities, newCity]);
  };

  // Filter events based on selected city for display
  const filteredEvents = selectedFilterCity
    ? events.filter(event => event.city === selectedFilterCity)
    : events;

  const toggleFreeTickets = (eventId) => {
    console.log('Toggling free tickets for event:', eventId); // Add this line
    setShowFreeTickets(prev => ({
      ...prev,
      [eventId]: !prev[eventId]
    }));
  };

  return (
    <div className="event-management">
      <div className="event-form-container" ref={formRef}>
        <h3>{editingEvent ? 'Edit Event' : 'Create New Event'}</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-section">
            <h4>Basic Information</h4>
            <div className="form-group">
              <label htmlFor="title">Event Title:</label>
              <input
                id="title"
                type="text"
                name="title"
                value={newEvent.title}
                onChange={handleInputChange}
                placeholder="Enter a catchy title for your event"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="shortDescription">Short Description:</label>
              <textarea
                id="shortDescription"
                name="shortDescription"
                value={newEvent.shortDescription}
                onChange={handleInputChange}
                placeholder="Brief summary of your event (max 120 characters)"
                maxLength={120}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Full Description:</label>
              <textarea
                id="description"
                name="description"
                value={newEvent.description}
                onChange={handleInputChange}
                placeholder="Detailed description of your event (supports Markdown)"
                required
              />
            </div>
          </div>

          <div className="form-section">
            <h4>Event Details</h4>
            <div className="form-group">
              <label htmlFor="dateTime">Event Start Date and Time:</label>
              <input
                id="dateTime"
                type="datetime-local"
                name="dateTime"
                value={newEvent.dateTime}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="endDateTime">Event End Date and Time (optional):</label>
              <input
                id="endDateTime"
                type="datetime-local"
                name="endDateTime"
                value={newEvent.endDateTime}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="location">Event Location:</label>
              <input
                id="location"
                type="text"
                name="location"
                value={newEvent.location}
                onChange={handleInputChange}
                placeholder="Enter the venue or address of the event"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="vendor">Event Vendor:</label>
              <input
                id="vendor"
                type="text"
                name="vendor"
                value={newEvent.vendor}
                onChange={handleInputChange}
                placeholder="Name of the event organizer or vendor"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="priceRange">Price Range:</label>
              <input
                id="priceRange"
                type="text"
                name="priceRange"
                value={newEvent.priceRange}
                onChange={handleInputChange}
                placeholder="e.g., £20 - £50"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastEntry">Last Entry Time:</label>
              <input
                id="lastEntry"
                type="text"
                name="lastEntry"
                value={newEvent.lastEntry}
                onChange={handleInputChange}
                placeholder="e.g., 11:00 PM"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="ageRestriction">Age Restriction:</label>
              <input
                id="ageRestriction"
                type="text"
                name="ageRestriction"
                value={newEvent.ageRestriction}
                onChange={handleInputChange}
                placeholder="e.g., 18+, All Ages, etc."
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="ticketSalesCutoff">Ticket Sales Cutoff:</label>
              <input
                id="ticketSalesCutoff"
                type="datetime-local"
                name="ticketSalesCutoff"
                value={newEvent.ticketSalesCutoff}
                onChange={handleInputChange}
              />
              <small>Leave blank if tickets can be sold until the event starts</small>
            </div>
          </div>

          <div className="form-section">
            <h4>Ticket Types</h4>
            <TicketTypeForm 
              ticketTypes={newEvent.ticketTypes}
              handleInputChange={handleInputChange}
              addTicketType={addTicketType}
              removeTicketType={removeTicketType}
            />
          </div>

          <div className="form-section">
            <h4>Event Images</h4>
            <div className="form-group">
              <label htmlFor="eventImages">Upload Event Images:</label>
              <input
                id="eventImages"
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              <small>You can select multiple images</small>
            </div>
            <div className="image-preview">
              {previewImages.map((image, index) => (
                <img key={index} src={image} alt={`Preview ${index + 1}`} />
              ))}
            </div>
            {uploadProgress > 0 && <LoadingBar progress={uploadProgress} />}
            {uploadError && <p className="error-message">{uploadError}</p>}
          </div>

          <div className="form-section">
            <CityManagement onCityAdded={handleCityAdded} />
            <div className="form-group">
              <label htmlFor="city">Event City:</label>
              <select
                id="city"
                name="city"
                value={newEvent.city || ''}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a city</option>
                {cities.length > 0 ? (
                  cities.map(city => (
                    <option key={city._id} value={city.name}>{city.name}</option>
                  ))
                ) : (
                  <option value="" disabled>No cities available</option>
                )}
              </select>
            </div>
          </div>

          {/* Move the featured event checkbox to the bottom */}
          <div className="form-group checkbox-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                name="isFeatured"
                checked={newEvent.isFeatured}
                onChange={handleInputChange}
              />
              Featured Event
            </label>
          </div>

          <button type="submit">{editingEvent ? 'Update Event' : 'Create Event'}</button>
          {editingEvent && <button type="button" onClick={resetForm}>Cancel Edit</button>}
        </form>
      </div>

      <div className="events-list">
        <h3>Existing Events</h3>
        <button onClick={handleUpdateEventStatuses}>Update Event Statuses</button>
        <EventFilter
          cities={cities}
          selectedCity={selectedFilterCity}
          onCityChange={handleFilterCityChange}
          label="Filter events by city"
        />
        {isLoading ? (
          <p>Loading events...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : filteredEvents.length > 0 ? (
          filteredEvents.map(event => (
            <div key={event._id} className={`event-item ${event.isFeatured ? 'featured' : ''}`}>
              <h4>{event.title}</h4>
              {event.isFeatured && <span className="featured-label">Featured</span>}
              <ReactMarkdown
                rehypePlugins={[[rehypeSanitize, customSchema]]}
                components={{
                  h1: ({ node, ...props }) => <h1 className="event-description-h1" {...props} />,
                  h2: ({ node, ...props }) => <h2 className="event-description-h2" {...props} />,
                  h3: ({ node, ...props }) => <h3 className="event-description-h3" {...props} />,
                  p: ({ node, ...props }) => <p className="event-description-p" {...props} />,
                  strong: ({ node, ...props }) => <strong className="event-description-strong" {...props} />,
                  em: ({ node, ...props }) => <em className="event-description-em" {...props} />,
                  a: ({ node, ...props }) => <a className="event-description-link" target="_blank" rel="noopener noreferrer" {...props} />,
                  ul: ({ node, ...props }) => <ul className="event-description-ul" {...props} />,
                  ol: ({ node, ...props }) => <ol className="event-description-ol" {...props} />,
                  li: ({ node, ...props }) => <li className="event-description-li" {...props} />,
                  hr: ({ node, ...props }) => <hr className="event-description-hr" {...props} />,
                }}
              >
                {event.description}
              </ReactMarkdown>
              <p>Date: {event.formattedDateTime}</p>
              <p>Location: {event.location}</p>
              <p>Vendor: {event.vendor}</p>
              <p>Price Range: {event.priceRange}</p>
              <p>Last Entry: {event.lastEntry}</p>
              <p>Age Restriction: {event.ageRestriction}</p>
              <p>Featured: {event.isFeatured ? 'Yes' : 'No'}</p>
              {event.ticketSalesCutoff && (
                <p>Ticket sales end on: {new Date(event.ticketSalesCutoff).toLocaleString()}</p>
              )}
              
              <TicketTypeDisplay 
                ticketTypes={event.ticketTypes}
                ticketCounts={ticketCounts}
                eventId={event._id}
              />
              
              <div className="event-total-counts">
                <span className="event-total-count">
                  Total Created: {ticketCounts[event._id]?.totalCount || '0'}
                </span>
                <span className="event-total-count">
                  Total Scanned: {ticketCounts[event._id]?.totalScanned || '0'}
                </span>
              </div>
              <h5>Images:</h5>
              <div className="event-images">
                {event.images && event.images.map((image, index) => (
                  <img key={index} src={image} alt={`Event ${index + 1}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                ))}
              </div>
              <div className="event-actions">
                <div className="primary-actions">
                  <button className="edit-btn" onClick={() => handleEditEvent(event)}>Edit</button>
                  <button className="delete-btn" onClick={() => handleDeleteEvent(event._id)}>Delete</button>
                </div>
                <div className="secondary-actions">
                  <button 
                    className="toggle-btn"
                    onClick={() => setShowFinancials(prev => ({ ...prev, [event._id]: !prev[event._id] }))}
                  >
                    {showFinancials[event._id] ? 'Hide Financials' : 'Show Financials'}
                  </button>
                  <button 
                    className="toggle-btn"
                    onClick={() => setShowReferrals(prev => ({ ...prev, [event._id]: !prev[event._id] }))}
                  >
                    {showReferrals[event._id] ? 'Hide Referrals' : 'Show Referrals'}
                  </button>
                  <button 
                    className="toggle-btn"
                    onClick={() => toggleFreeTickets(event._id)}
                  >
                    {showFreeTickets[event._id] ? 'Hide Free Tickets' : 'Manage Free Tickets'}
                  </button>
                </div>
              </div>
              {showFinancials[event._id] && <EventFinancials eventId={event._id} />}
              {showReferrals[event._id] && <ReferralSystem event={event} onReferralUpdate={handleReferralUpdate} />}
              {showFreeTickets[event._id] && (
                <>
                  <h4>Free Tickets Management</h4>
                  <FreeTicketsManager 
                    eventId={event._id} 
                    key={event._id}
                  />
                  {console.log('Rendering FreeTicketsManager with eventId:', event._id)}
                </>
              )}
            </div>
          ))
        ) : (
          <p>No events found. Create a new event to get started.</p>
        )}
      </div>
    </div>
  );
};

export default EventManagement;
