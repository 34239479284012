import posthog from 'posthog-js'
import { useEffect } from 'react'

const usePostHog = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_POSTHOG === 'true') {
      posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
        api_host: `${window.location.origin}/ph`, // Use your own domain
        autocapture: true,
        capture_pageview: false, // Disable automatic pageview capture
        persistence: 'localStorage',
        person_profiles: 'always',
        loaded: (posthog) => {
          if (process.env.NODE_ENV === 'development') posthog.debug()
        }
      })
    } else {
      console.log('PostHog is disabled')
    }
  }, [])

  return posthog
}

export default usePostHog