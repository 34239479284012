import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, useNavigate, useLocation, Navigate, useOutletContext } from 'react-router-dom';
import { useLanguage } from '../Translations/LanguageContext';
import Footer from '../components/Footer/Footer';
import api, { fetchCSRFToken } from '../api';
import usePostHog from './posthog';

/**
 * Protects routes that require authentication.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render if authenticated.
 * @param {string} props.redirectTo - The path to redirect to if not authenticated.
 * @returns {React.ReactElement} The protected route or a redirect.
 */
export const ProtectedRoute = ({ children, redirectTo, adminOnly = false }) => {
  const { user, isLoading } = useOutletContext();

  
  if (isLoading) {
    return <div></div>;
  }
  
  if (!user) {
    return <Navigate to={redirectTo} />;
  }
  
  if (adminOnly && !user.isAdmin) {
    return <Navigate to="/" />;
  }
  
  return children;
};

/**
 * Main content component for the application.
 * Handles authentication, routing, and provides context to child components.
 *
 * @component
 * @returns {React.ReactElement} The main application content.
 */
function AppContent() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();
  const isProduction = process.env.REACT_APP_ENABLE_POSTHOG === 'true';

  const publicRoutes = ['robots.txt', '/', '/landing', '/events', '/settings', '/forgot-password', '/reset-password', '/verify-ticket', '/contact', '/about', '/work-with-us'];

  const checkAuth = useCallback(async () => {
    try {
      const authResponse = await api.checkAuth();
      if (authResponse.status === 204) {
        console.log('No user is logged in');
        // No content means no user is logged in
        setUser(null);
      } else if (authResponse.data.isAuthenticated) {
        setUser(authResponse.data.user);
        if (authResponse.data.user.isOrganizer) {
          const organizedEvents = await api.getOrganizedEvents();
          setUser(prevUser => ({ ...prevUser, organizedEvents: organizedEvents.data }));
        }
      } else {
        setUser(null);
        console.log('No user is logged in!');
      }
    } catch (error) {
      // Only log errors that are not 401 or 204
      if (error.response && error.response.status !== 401 && error.response.status !== 204) {
        console.error('Auth check error:', error);
      }
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    if (!isLoading && !user) {
      const isPublicRoute = publicRoutes.some(route => location.pathname.startsWith(route));
      
      if (!isPublicRoute) {
        navigate('/');
      }
    }
  }, [user, isLoading, navigate, location.pathname]);

  // Checks for auth on tab change and window focus (for when user logs out on a different page and comes back to the home page)
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkAuth();
      }
    };

    const handleFocus = () => {
      checkAuth();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, [checkAuth]);


  useEffect(() => {
    if (isProduction && !isLoading) {
      posthog.capture('$pageview', {
        $current_url: window.location.href,
        $pathname: location.pathname,
        $search: location.search
      });
    }
  }, [location, posthog, isProduction, isLoading]);

  useEffect(() => {
    const initialLoadingElement = document.querySelector('.initial-loading');
    if (initialLoadingElement) {
      initialLoadingElement.remove();
    }
  }, []); // Run this effect only once on mount

  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false); // Set to true to enable maintenance mode

  if (isMaintenanceMode) {
    return (
      <div className="maintenance-page">
        <h1>Maintenance Mode</h1>
        <p>Apologies Our site is currently undergoing maintenance. Please check back later.</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="react-loading">
        <h1 className="react-loading-title">Small Potatoes Loading...</h1>
        <div className="react-loading-spinner"></div>
      </div>
    );
  }


  return (
    <div className="App">
      <main className="main-content">
        <Outlet context={{
          user,
          setUser,
          handleLoginSuccess: (userData) => {
            setUser(userData);
            if (isProduction) {
              posthog.identify(userData.id, {
                name: userData.name,
                email: userData.email,
              });
            }
          },
          handleLogout: async () => {
            await api.logout();
            setUser(null);
            if (isProduction) {
              posthog.reset();
            }
            navigate('/');
          },
          language,
          setLanguage,
          isLoading
        }} />
      </main>
      <Footer />
    </div>
  );
}

export default AppContent;
