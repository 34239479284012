import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../api';
import './ReferralSystem.css';

const ReferralSystem = ({ event, onReferralUpdate }) => {
  const [copySuccess, setCopySuccess] = useState('');
  const [showOrders, setShowOrders] = useState({});
  const [orderDetails, setOrderDetails] = useState({});

  const generateReferralCode = async () => {
    try {
      const code = uuidv4().slice(0, 8);
      const influencer = prompt("Enter influencer's name:");
      if (influencer) {
        const response = await api.createReferralCode(event._id, code, influencer);
        onReferralUpdate(event._id, [...event.referralCodes, response.data]);
      }
    } catch (error) {
      console.error('Error generating referral code:', error);
      alert('An error occurred while generating the referral code. Please try again.');
    }
  };

  const handleDeleteReferralCode = async (referralId) => {
    if (window.confirm('Are you sure you want to delete this referral code?')) {
      try {
        await api.deleteReferralCode(event._id, referralId);
        onReferralUpdate(event._id, event.referralCodes.filter(code => code._id !== referralId));
      } catch (error) {
        console.error('Error deleting referral code:', error);
        alert('Failed to delete referral code. Please try again.');
      }
    }
  };

  const copyReferralLink = (code) => {
    // Use the event's slug instead of _id
    const referralLink = `${window.location.origin}/events/${event.slug}?ref=${code}`;
    navigator.clipboard.writeText(referralLink).then(() => {
      setCopySuccess(`Copied: ${code}`);
      setTimeout(() => setCopySuccess(''), 3000);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const fetchOrderDetails = async (referralId) => {
    try {
      const response = await api.getReferralOrders(event._id, referralId);
      setOrderDetails(prevDetails => ({
        ...prevDetails,
        [referralId]: response.data
      }));
    } catch (error) {
      console.error('Error fetching order details:', error);
      alert('Failed to fetch order details. Please try again.');
    }
  };

  const toggleShowOrders = (referralId) => {
    setShowOrders(prevState => ({
      ...prevState,
      [referralId]: !prevState[referralId]
    }));
    if (!orderDetails[referralId]) {
      fetchOrderDetails(referralId);
    }
  };

  return (
    <div className="referral-system">
      <h5>Referral Codes:</h5>
      <button onClick={generateReferralCode} className="generate-referral-btn">Generate Referral Code</button>
      <ul className="referral-code-list">
        {event.referralCodes && event.referralCodes.length > 0 ? (
          event.referralCodes.map((code) => (
            <li key={code._id} className="referral-code-item">
              <span className="referral-code-info">
                {code.influencer}: {code.code} (Clicks: {code.clicks}, Orders: {code.orders ? code.orders.length : 0})
              </span>
              <div className="referral-code-actions">
                <button onClick={() => copyReferralLink(code.code)} className="copy-referral-btn">
                  Copy Link
                </button>
                <button onClick={() => toggleShowOrders(code._id)} className="show-orders-btn">
                  {showOrders[code._id] ? 'Hide Summary' : 'Show Summary'}
                </button>
                <button onClick={() => handleDeleteReferralCode(code._id)} className="delete-referral-btn">
                  Delete
                </button>
              </div>
              {showOrders[code._id] && (
                <div className="order-details">
                  {orderDetails[code._id] ? (
                    <>
                      <div className="order-summary">
                        <p>Total Earned: £{orderDetails[code._id].totalEarned.toFixed(2)}</p>
                        <p>Total Orders: {orderDetails[code._id].orderCount}</p>
                        <p>Total Tickets Sold: {Object.values(orderDetails[code._id].ticketTypes).reduce((sum, type) => sum + type.quantity, 0)}</p>
                      </div>
                      <div className="ticket-summary">
                        <h6>Tickets Sold by Type:</h6>
                        <ul className="ticket-list">
                          {Object.entries(orderDetails[code._id].ticketTypes).map(([type, data]) => (
                            <li key={type}>
                              <span>{type}</span>
                              <span>Quantity: {data.quantity}</span>
                              <span>Total: £{data.totalPrice.toFixed(2)}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  ) : (
                    <p>Loading order details...</p>
                  )}
                </div>
              )}
            </li>
          ))
        ) : (
          <li>No referral codes yet</li>
        )}
      </ul>
      {copySuccess && <div className="copy-success">{copySuccess}</div>}
    </div>
  );
};

export default ReferralSystem;
