import React from 'react';

const EventFilter = ({ cities, selectedCity, onCityChange, label = "Filter by city" }) => {
  return (
    <div className="event-filter">
      <label htmlFor="city-filter">{label}:</label>
      <select
        id="city-filter"
        value={selectedCity}
        onChange={onCityChange}
      >
        <option value="">All Cities</option>
        {cities.map(city => (
          <option key={city._id} value={city.name}>{city.name}</option>
        ))}
      </select>
    </div>
  );
};

export default EventFilter;
