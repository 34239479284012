import React, { useState, useEffect } from 'react';
import api from '../../../api';
import './EventFinancials.css';

const EventFinancials = ({ eventId }) => {
  const [financials, setFinancials] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFinancials = async () => {
      try {
        const response = await api.getEventFinancials(eventId);
        setFinancials(response.data);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load financial data');
        setIsLoading(false);
      }
    };

    fetchFinancials();
  }, [eventId]);

  if (isLoading) return <div>Loading financial data...</div>;
  if (error) return <div>{error}</div>;
  if (!financials) return null;

  const { totalRevenue, owedToOrganizer, owedToUs, estimatedStripeFee, totalOrders } = financials;

  const stripeFeePercentage = totalRevenue * 0.029;
  const stripeFeeFixed = totalOrders * 0.30;

  return (
    <div className="event-financials">
      <h3>Financial Summary</h3>
      <div className="financial-item">
        <span>Total Revenue:</span>
        <span>£{totalRevenue.toFixed(2)}</span>
      </div>
      <div className="financial-item">
        <span>Owed to Organizer:</span>
        <span>£{owedToOrganizer.toFixed(2)}</span>
      </div>
      <div className="financial-item">
        <span>Owed to Us:</span>
        <span>£{owedToUs.toFixed(2)}</span>
      </div>
      <div className="financial-item">
        <span>Estimated Stripe Fee:</span>
        <span>£{estimatedStripeFee.toFixed(2)}</span>
      </div>
      <div className="financial-item">
        <span>Stripe Fee Breakdown:</span>
        <span>
          2.9% (£{stripeFeePercentage.toFixed(2)}) + £0.30 per order (£{stripeFeeFixed.toFixed(2)})
        </span>
      </div>
      <div className="financial-item">
        <span>Total Orders:</span>
        <span>{totalOrders}</span>
      </div>
    </div>
  );
};

export default EventFinancials;
