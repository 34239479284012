import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from '../../../../api';
import './Confirmation.css';
import Header from '../../../Header/Header';
import VoucherCard from '../../../HomePage/VoucherCard/VoucherCard';

/**
 * Represents the order confirmation page.
 *
 * @component
 * @returns {React.ReactElement} The order confirmation page.
 */
const Confirmation = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [voucherPreviews, setVoucherPreviews] = useState([]);

  useEffect(() => {
    const fetchOrderAndVouchers = async () => {
      try {
        const [orderResponse, vouchersResponse] = await Promise.all([
          api.getOrder(orderId),
          api.getVoucherPreviews(6) // Fetch 8 vouchers instead of 4
        ]);
        setOrder(orderResponse.data);
        setVoucherPreviews(Array.isArray(vouchersResponse) ? vouchersResponse : []);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrderAndVouchers();
  }, [orderId]);

  const renderOrderStatus = () => {
    switch(order.status) {
      case 'completed':
        return (
          <>
            <div className="order-details">
              <p><strong>Event:</strong> {order.event.title}</p>
              <p><strong>Date:</strong> {new Date(order.event.dateTime).toLocaleDateString()}</p>
              <p><strong>Total Paid:</strong> £{order.total.toFixed(2)}</p>
            </div>
            <p className="email-sent">Your tickets and receipt have been emailed to you.</p>
          </>
        );
      case 'pending':
        return (
          <div className="pending-message">
            <p>Your payment is still being processed. Please check back later.</p>
            <p>Order ID: {order.orderId}</p>
          </div>
        );
      case 'cancelled':
      case 'failed':
        return (
          <div className="failed-message">
            <p>We're sorry, but your payment was not successful.</p>
            <p>Order ID: {order.orderId}</p>
            <p>Please try purchasing your tickets again.</p>
            <Link to={`/events/${order.event._id}`} className="retry-purchase">Retry Purchase</Link>
          </div>
        );
      default:
        return <div className="error">Unknown order status</div>;
    }
  };

  const renderVoucherPreviews = () => {
    if (!Array.isArray(voucherPreviews) || voucherPreviews.length === 0) {
      return null;
    }
    
    return (
      <div className="voucher-previews confirmation-voucher-previews">
        <h3>Check out our latest vouchers:</h3>
        <div className="voucher-preview-list">
          {voucherPreviews.map(voucher => (
            <VoucherCard
              key={voucher._id}
              voucher={voucher}
              onClick={() => {}}
              ribbonType={voucher.ribbonType}
              redirectToHome={true}
              className="confirmation-voucher-card"
            />
          ))}
        </div>
        <Link to="/vouchers" className="view-all-vouchers">View All Vouchers</Link>
      </div>
    );
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!order) return <div className="error">No order found</div>;

  return (
    <>
      <Header />
      <div className="confirmation-container">
        <div className="confirmation-card">
          <h2>Booking Confirmation</h2>
          {renderOrderStatus()}
          {order && order.status === 'completed' && renderVoucherPreviews()}
          <Link to="/" className="back-to-home">Back to Home</Link>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
