import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import './AdminPage.css';
import CardManagement from './CardManagement/CardManagement';
import VoucherManagement from './VoucherManagement/VoucherManagement';
import CompanyManagement from './CompanyManagement/CompanyManagement';
import EventManagement from './EventManagement/EventManagement';
import UserManagement from './UserManagement/UserManagement';

/**
 * Represents the Admin Dashboard component.
 * Manages different sections of admin functionality including cards, vouchers, companies, events, and users.
 *
 * @component
 * @returns {React.ReactElement} The Admin Dashboard UI.
 */
function AdminPage() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [activeTab, setActiveTab] = useState('cards');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.checkAuth();
        if (response.data.user && response.data.user.isAdmin) {
          setIsAdmin(true);
          // Fetch other data only if user is admin
          const [cardsResponse, companiesResponse, usersResponse] = await Promise.all([
            api.getAllCards(),
            api.getAllCompanies(),
            api.getAllUsers()
          ]);
          setCards(cardsResponse.data);
          setCompanies(companiesResponse.data);
          setUsers(usersResponse.data);
        } else {
          navigate('/');
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);
 
  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleReturnToProfile = () => {
    navigate('/home');
  };

  const updateCards = async () => {
    try {
      const cardsResponse = await api.getAllCards();
      setCards(cardsResponse.data);
    } catch (error) {
      console.error('Error updating cards:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="admin-page">
      <h1>Admin Dashboard</h1>
      <button onClick={handleReturnToProfile} className="return-button">Return to Profile</button>
      
      <div className="admin-tabs">
        <button onClick={() => setActiveTab('cards')} className={activeTab === 'cards' ? 'active' : ''}>Cards</button>
        <button onClick={() => setActiveTab('vouchers')} className={activeTab === 'vouchers' ? 'active' : ''}>Vouchers</button>
        <button onClick={() => setActiveTab('companies')} className={activeTab === 'companies' ? 'active' : ''}>Companies</button>
        <button onClick={() => setActiveTab('events')} className={activeTab === 'events' ? 'active' : ''}>Events</button>
        <button onClick={() => setActiveTab('users')} className={activeTab === 'users' ? 'active' : ''}>Users</button>
      </div>

      {activeTab === 'cards' && <CardManagement cards={cards} setCards={setCards} />}
      {activeTab === 'vouchers' && (
        <VoucherManagement 
          cards={cards} 
          companies={companies} 
          updateCards={updateCards}
        />
      )}
      {activeTab === 'companies' && <CompanyManagement companies={companies} setCompanies={setCompanies} />}
      {activeTab === 'events' && <EventManagement />}
      {activeTab === 'users' && <UserManagement setUsers={setUsers} />}
    </div>
  );
}

export default AdminPage;