import React from 'react';
import './TrialPopup.css';
import { useTranslation } from '../../hooks/useTranslation';

const TrialPopup = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <div className="trial-popup-overlay">
      <div className="trial-popup">
        <h2>{t.trialPopupTitle}</h2>
        <p>{t.trialPopupDescription}</p>
        <button onClick={onClose} className="dismiss-button">{t.dismissButton}</button>
      </div>
    </div>
  );
};

export default TrialPopup;