import React, { useState, useEffect } from 'react';
import api from '../../api';
import TermsAndConditions from './TermsAndConditions';
import './Register.css';
import { differenceInYears, parse, isValid } from 'date-fns';
import usePostHog from '../../AppContent/posthog'; // Import the PostHog hook

const isValidDay = (day) => {
  const parsedDay = parseInt(day, 10);
  return !isNaN(parsedDay) && parsedDay >= 1 && parsedDay <= 31;
};

const isValidYear = (year) => {
  const parsedYear = parseInt(year, 10);
  const currentYear = new Date().getFullYear();
  return !isNaN(parsedYear) && parsedYear >= 1900 && parsedYear <= currentYear;
};

/**
 * Represents the user registration component with multi-step form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onRegisterSuccess - Callback function for successful registration.
 * @param {Function} props.onRegistrationComplete - Callback function when registration is complete.
 * @param {Function} props.onShowTerms - Function to show terms and conditions.
 * @param {number} props.step - Current step of the registration process.
 * @param {Function} props.setStep - Function to set the current step.
 * @param {string} props.name - User's name.
 * @param {Function} props.setName - Function to set user's name.
 * @param {string} props.email - User's email.
 * @param {Function} props.setEmail - Function to set user's email.
 * @param {string} props.confirmEmail - User's confirmed email.
 * @param {Function} props.setConfirmEmail - Function to set user's confirmed email.
 * @param {string} props.birthMonth - User's birth month.
 * @param {Function} props.setBirthMonth - Function to set user's birth month.
 * @param {string} props.birthDay - User's birth day.
 * @param {Function} props.setBirthDay - Function to set user's birth day.
 * @param {string} props.birthYear - User's birth year.
 * @param {Function} props.setBirthYear - Function to set user's birth year.
 * @param {string} props.password - User's password.
 * @param {Function} props.setPassword - Function to set user's password.
 * @param {string} props.repeatPassword - User's repeated password.
 * @param {Function} props.setRepeatPassword - Function to set user's repeated password.
 * @param {boolean} props.acceptMarketing - Whether user accepts marketing communications.
 * @param {Function} props.setAcceptMarketing - Function to set user's marketing preference.
 * @param {string} props.verificationCode - User's verification code.
 * @param {Function} props.setVerificationCode - Function to set verification code.
 * @param {boolean} props.verificationSent - Whether verification code has been sent.
 * @param {Function} props.setVerificationSent - Function to set verification sent status.
 * @returns {React.ReactElement} A multi-step registration form element.
 */
const Register = ({
  onRegisterSuccess,
  onRegistrationComplete,
  onShowTerms,
  step,
  setStep,
  name,
  setName,
  email,
  setEmail,
  confirmEmail,
  setConfirmEmail,
  birthMonth,
  setBirthMonth,
  birthDay,
  setBirthDay,
  birthYear,
  setBirthYear,
  password,
  setPassword,
  repeatPassword,
  setRepeatPassword,
  acceptMarketing,
  setAcceptMarketing,
  verificationCode,
  setVerificationCode,
  verificationSent,
  setVerificationSent
}) => {
  const [error, setError] = useState('');
  const [showTerms, setShowTerms] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const currentYear = new Date().getFullYear();

  const posthog = usePostHog(); // Use the PostHog hook

  useEffect(() => {
    // Capture event when registration is opened
    posthog.capture('registration_opened');

    // Cleanup function to capture when user leaves registration
    return () => {
      posthog.capture('registration_closed', { last_step: step });
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  useEffect(() => {
    // Capture event when user progresses to a new step
    if (step > 1) {
      posthog.capture('registration_step_reached', { step });
    }
  }, [step]);

  useEffect(() => {
    let interval;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setIsResendDisabled(false);
    }
    return () => clearInterval(interval);
  }, [resendTimer]);

  const validateName = (name) => {
    return name.trim().length >= 2 && name.trim().length <= 120 && /^[a-zA-Z\s]+$/.test(name);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password);
  };

  const validateDateOfBirth = (month, day, year) => {
    if (!month || !day || !year) return false;
    if (!isValidDay(day) || !isValidYear(year)) return false;
    const birthDate = parse(`${year}-${month}-${day}`, 'yyyy-MM-dd', new Date());
    if (!isValid(birthDate)) return false;
    const age = differenceInYears(new Date(), birthDate);
    return age >= 13;
  };

  const handleNext = async (e) => {
    e.preventDefault();
    setError('');

    if (step === 1) {
      if (!validateName(name)) {
        setError('Name must be between 2 and 120 characters and contain only letters and spaces');
        return;
      }

      if (!validateEmail(email)) {
        setError('Invalid email address');
        return;
      }

      if (email !== confirmEmail) {
        setError('Email addresses do not match');
        return;
      }

      if (!validateDateOfBirth(birthMonth, birthDay, birthYear)) {
        setError('Please enter a valid date of birth. You must be at least 13 years old to register.');
        return;
      }

      try {
        const response = await api.checkExistingUser(email);
        if (response.data.emailExists) {
          setError('This email is already registered');
          posthog.capture('registration_error', { error: 'email_exists' });
        } else {
          setStep(2);
          posthog.capture('registration_step_completed', { step: 1 });
          if (!verificationSent) {
            await sendVerificationCode();
            setVerificationSent(true);
          }
        }
      } catch (err) {
        console.error('Error checking existing user:', err);
        setError('An error occurred. Please try again.');
        posthog.capture('registration_error', { error: 'api_error', details: err.message });
      }
    } else if (step === 2) {
      // Validate verification code
      try {
        const response = await api.verifyCode(email, verificationCode);
        if (response.data.success) {
          setStep(3);
          posthog.capture('registration_step_completed', { step: 2 });
        } else {
          setError('Invalid verification code. Please try again.');
          posthog.capture('registration_error', { error: 'invalid_verification_code' });
        }
      } catch (err) {
        console.error('Error verifying code:', err);
        setError('An error occurred. Please try again.');
        posthog.capture('registration_error', { error: 'verification_api_error', details: err.message });
      }
    }
  };

  const sendVerificationCode = async () => {
    try {
      await api.sendVerificationCode(email);
    } catch (err) {
      console.error('Error sending verification code:', err);
      setError('Failed to send verification code. Please try again.');
    }
  };

  const handleResendCode = async () => {
    if (!isResendDisabled) {
      setIsResendDisabled(true);
      setResendTimer(60); // Set a 60-second cooldown
      await sendVerificationCode();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePassword(password)) {
      setError('Password does not meet the required criteria. Please check the password rules and try again.');
      posthog.capture('registration_error', { error: 'invalid_password' });
      return;
    }

    if (password !== repeatPassword) {
      setError('Passwords do not match');
      posthog.capture('registration_error', { error: 'passwords_mismatch' });
      return;
    }

    try {
      const response = await api.post('/users/register', {
        name,
        email: email.toLowerCase(),
        confirmEmail,
        birthMonth,
        birthDay,
        birthYear,
        password,
        acceptMarketing,
        verificationCode
      });
      if (response.data && response.data.user) {
        // Automatically log the user in
        const loginResponse = await api.login(email, password, false);
        if (loginResponse.data && loginResponse.data.user) {
          localStorage.setItem('user', JSON.stringify(loginResponse.data.user));
          onRegisterSuccess(loginResponse.data.user);
          posthog.capture('registration_completed');
        } else {
          throw new Error('Invalid login response from server');
        }
      } else {
        throw new Error('Invalid registration response from server');
      }
    } catch (err) {
      console.error('Registration error:', err.response?.data || err.message);
      
      // Check if the error is related to CSRF
      if (err.response?.status === 403 && err.response?.data?.message?.includes('CSRF')) {
        setError('Connection error. Please try using a different network (e.g., mobile data) or try again later.');
        posthog.capture('registration_error', { error: 'csrf_error', details: err.message });
      } else {
        setError(err.response?.data?.message || 'An error occurred during registration');
        posthog.capture('registration_error', { error: 'final_submission_error', details: err.message });
      }
    }
  };

  const handleTermsClick = (e) => {
    e.preventDefault();
    onShowTerms(true);
  };

  const handleHideTerms = () => {
    setShowTerms(false);
  };

  return (
    <div className="register-container auth-form-container">
      {showTerms ? (
        <TermsAndConditions onBack={handleHideTerms} fromRegistration={true} />
      ) : (
        <>
          {error && <div className="error-message">{error}</div>}
          <form onSubmit={step === 3 ? handleSubmit : handleNext} className="register-form auth-form">
            {/* Hidden username field for accessibility */}
            <input type="text" autoComplete="username" style={{ display: 'none' }} aria-hidden="true" />

            {step === 1 && (
              <>
                <div className="form-group">
                  <label htmlFor="register-name">Name:</label>
                  <input
                    type="text"
                    id="register-name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    autoComplete="name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="register-email">Email:</label>
                  <input
                    type="email"
                    id="register-email"
                    name="username"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    autoComplete="username"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="register-confirm-email">Confirm Email:</label>
                  <input
                    type="email"
                    id="register-confirm-email"
                    name="confirm-email"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Date of birth</label>
                  <div className="date-of-birth">
                    <select
                      id="register-birth-month"
                      value={birthMonth}
                      onChange={(e) => setBirthMonth(e.target.value)}
                      required
                    >
                      <option value="">Month</option>
                      {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                        <option key={month} value={month.toString().padStart(2, '0')}>
                          {new Date(2000, month - 1, 1).toLocaleString('default', { month: 'long' })}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      id="register-birth-day"
                      placeholder="Day"
                      value={birthDay}
                      onChange={(e) => setBirthDay(e.target.value)}
                      required
                      maxLength="2"
                      pattern="\d{1,2}"
                      title="Please enter a valid day (1-31)"
                    />
                    <input
                      type="text"
                      id="register-birth-year"
                      placeholder="Year"
                      value={birthYear}
                      onChange={(e) => setBirthYear(e.target.value)}
                      required
                      maxLength="4"
                      pattern="\d{4}"
                      title="Please enter a valid 4-digit year"
                    />
                  </div>
                </div>
                <button type="submit">Next</button>
              </>
            )}
            {step === 2 && (
              <>
                <div className="form-group">
                  <p>Please check your email for a verification code to continue with account creation.</p>
                  <label htmlFor="verification-code">Verification Code:</label>
                  <input
                    type="text"
                    id="verification-code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    required
                  />
                </div>
                <button type="submit">Verify</button>
                <p>
                  Didn't receive the code?{' '}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleResendCode();
                    }}
                    className={isResendDisabled ? 'disabled-link' : ''}
                  >
                    Resend {resendTimer > 0 ? `(${resendTimer}s)` : ''}
                  </a>
                </p>
              </>
            )}
            {step === 3 && (
              <>
                <p className='register-password-title'>Create a password to complete account creation</p>
                <div className={`password-rules ${isPasswordFocused ? 'visible' : ''}`}>
                  <p>Your password must:</p>
                  <ul>
                    <li className={password.length >= 8 ? 'met' : ''}>Be at least 8 characters long</li>
                    <li className={/[A-Z]/.test(password) ? 'met' : ''}>Include at least one uppercase letter</li>
                    <li className={/[a-z]/.test(password) ? 'met' : ''}>Include at least one lowercase letter</li>
                    <li className={/[0-9]/.test(password) ? 'met' : ''}>Include at least one number</li>
                  </ul>
                </div>
                <div className="form-group">
                  <label htmlFor="register-new-password">Password:</label>
                  <div className="password-input-wrapper">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="register-new-password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() => setIsPasswordFocused(true)}
                      onBlur={(e) => {
                        if (!e.relatedTarget || !e.relatedTarget.classList.contains('password-toggle')) {
                          setIsPasswordFocused(false);
                        }
                      }}
                      required
                      autoComplete="new-password"
                    />
                    <button
                      type="button"
                      className="password-toggle"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                        document.getElementById('register-new-password').focus();
                      }}
                    >
                      {showPassword ? "👁️" : "👁️‍🗨️"}
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="register-repeat-password">Repeat Password:</label>
                  <div className="password-input-wrapper">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="register-repeat-password"
                      name="new-password"
                      value={repeatPassword}
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      required
                      autoComplete="new-password"
                    />
                    <button
                      type="button"
                      className="password-toggle"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "👁️" : "👁️‍🗨️"}
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    <input
                      type="checkbox"
                      checked={acceptMarketing}
                      onChange={(e) => setAcceptMarketing(e.target.checked)}
                    />
                    Yes, I'd like to receive promotions and offers!
                  </label>
                  <p className="marketing-info">
                    We'll send you personalised offers and updates.
                    Don't worry, we value your privacy and will never share your email with third parties.
                  </p>
                </div>
                <div className="terms-and-button">
                  <p className="terms-text">
                    By creating an account, you agree to the Small Potatoes{' '}
                    <a href="#" onClick={handleTermsClick}>terms and conditions</a>
                  </p>
                  <button type="submit" className="register-button">Complete Registration</button>
                </div>
              </>
            )}
          </form>
        </>
      )}
    </div>
  );
};


export default Register;
