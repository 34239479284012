import React, { useState, useEffect } from 'react';
import api from '../../../api';
import './FreeTicketsManager.css';

function FreeTicketsManager({ eventId }) {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [selectedTicketType, setSelectedTicketType] = useState('');
    const [reason, setReason] = useState('');
    const [freeTickets, setFreeTickets] = useState([]);
    const [ticketTypes, setTicketTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [maxScans, setMaxScans] = useState(1);

    const fetchTicketTypes = async () => {
        try {
            if (!eventId) {
                console.error('No eventId provided to FreeTicketsManager');
                setError('No event ID provided');
                return;
            }

            console.log('Fetching ticket types for event:', eventId);
            const response = await api.getEventById(eventId);
            console.log('Raw event data:', response.data);

            if (response.data && Array.isArray(response.data.ticketTypes)) {
                // Extract the ticket type data directly from the response
                const validTicketTypes = response.data.ticketTypes.map(tt => ({
                    _id: tt._id,
                    title: tt.title,
                    description: tt.description,
                    price: tt.price,
                    badge: tt.badge,
                    quantity: tt.quantity,
                    sold: tt.sold || 0,
                    availableTickets: tt.availableTickets || (tt.quantity - (tt.sold || 0))
                })).filter(type => type._id && type.title);

                console.log('Processed ticket types:', validTicketTypes);
                setTicketTypes(validTicketTypes);
                setError(null);
            } else {
                console.log('No valid ticket types found in response:', response.data);
                setTicketTypes([]);
                setError('No ticket types found for this event');
            }
        } catch (error) {
            console.error('Error fetching ticket types:', error);
            setTicketTypes([]);
            if (error.response?.status === 404) {
                setError(`Event not found. ID: ${eventId}`);
            } else {
                setError(error.response?.data?.message || 'Error fetching ticket types');
            }
        }
    };

    const fetchFreeTickets = async () => {
        try {
            const response = await api.getFreeTicketsList(eventId);
            console.log('Fetched free tickets:', response.data);
            const validFreeTickets = response.data.filter(ticket => 
                ticket && typeof ticket === 'object' && ticket._id
            );
            setFreeTickets(validFreeTickets);
            setError(null);
        } catch (error) {
            console.error('Error fetching free tickets:', error);
            setFreeTickets([]);
            if (error.response?.status === 404) {
                setError('Unable to fetch free tickets. Please check the event ID.');
            } else {
                setError(error.response?.data?.message || 'Error fetching free tickets');
            }
        }
    };

    useEffect(() => {
        console.log('FreeTicketsManager mounted/updated with eventId:', eventId);
        if (eventId) {
            Promise.all([
                fetchTicketTypes(),
                fetchFreeTickets()
            ]).catch(error => {
                console.error('Error initializing free tickets manager:', error);
            });
        }
    }, [eventId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            await api.createFreeTickets(eventId, {
                email,
                name,
                ticketType: selectedTicketType,
                reason,
                maxScans: parseInt(maxScans)
            });

            // Reset form
            setEmail('');
            setName('');
            setSelectedTicketType('');
            setReason('');
            setMaxScans(1);
            
            // Refresh tickets list
            await fetchFreeTickets();
        } catch (error) {
            console.error('Error creating free ticket:', error);
            setError(error.response?.data?.message || 'Error creating free ticket');
        } finally {
            setLoading(false);
        }
    };

    const confirmDeletion = (itemType) => {
        const confirmationPhrase = 'I am sure';
        let warningMessage = `Warning: Revoking this ${itemType} cannot be undone.`;
        
        warningMessage += ` Revoking ${itemType} can be damaging to the business and users. ` +
            'It may result in loss of user trust and potential issues. Please ask Chris for confirmation.';
        
        warningMessage += `\n\nIf you understand the consequences and still want to proceed, ` +
            `type "${confirmationPhrase}" to confirm revocation:`;
      
        const userInput = prompt(warningMessage);
        return userInput === confirmationPhrase;
    };

    const handleRevoke = async (ticketId) => {
        if (confirmDeletion('free ticket')) {
            try {
                await api.revokeFreeTicket(ticketId, eventId);
                fetchFreeTickets();
            } catch (error) {
                console.error('Error revoking ticket:', error);
                setError('Error revoking ticket');
            }
        }
    };

    return (
        <div className="free-tickets-manager">
            <h3>Issue Free Tickets</h3>
            
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Guest Email"
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Guest Name"
                        required
                    />
                </div>
                <div className="form-group">
                    <select
                        value={selectedTicketType}
                        onChange={(e) => setSelectedTicketType(e.target.value)}
                        required
                    >
                        <option value="">Select Ticket Type</option>
                        {ticketTypes.map(type => (
                            <option key={type._id} value={type._id}>
                                {type.title} {type.price !== undefined && type.price !== null ? 
                                    `(£${Number(type.price).toFixed(2)})` : ''}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        placeholder="Reason for free ticket"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="maxScans">Maximum Entry Count:</label>
                    <input
                        id="maxScans"
                        type="number"
                        min="1"
                        value={maxScans}
                        onChange={(e) => setMaxScans(e.target.value)}
                        placeholder="Max number of entries"
                        required
                    />
                    <small>How many times can this ticket be scanned?</small>
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Creating...' : 'Issue Free Ticket'}
                </button>
            </form>

            {error && <div className="error-message">{error}</div>}

            <div className="free-tickets-list">
                <h4>Issued Free Tickets</h4>
                <table>
                    <thead>
                        <tr>
                            <th>Guest</th>
                            <th>Ticket Type</th>
                            <th>Reason</th>
                            <th>Status</th>
                            <th>Scans</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {freeTickets.map(ticket => (
                            <tr key={ticket._id}>
                                <td>{ticket.user?.email}</td>
                                <td>
                                    {ticket.ticketType?.title} 
                                    {ticket.ticketType?.price !== undefined && 
                                     ticket.ticketType?.price !== null && 
                                     ` (£${Number(ticket.ticketType.price).toFixed(2)})`}
                                </td>
                                <td>{ticket.metadata?.reason}</td>
                                <td>{ticket.status}</td>
                                <td>
                                    {ticket.metadata?.scanCount || 0} / {ticket.metadata?.maxScans || 1}
                                </td>
                                <td>
                                    {ticket.status === 'valid' && (
                                        <button 
                                            onClick={() => handleRevoke(ticket._id)}
                                            className="revoke-btn"
                                            title="Revoke this free ticket"
                                        >
                                            Revoke
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FreeTicketsManager; 