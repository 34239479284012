import React, { useState, useRef, useEffect } from 'react';
import Login from '../Login/Login';
import Register from '../Register/Register';
import TermsAndConditions from '../Register/TermsAndConditions';
import './AuthPopup.css';
import BackButton from '../Ui/BackButton';

/**
 * Represents an authentication popup component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.mode - The initial mode of the popup ('login' or 'register').
 * @param {Function} props.onClose - Function to close the popup.
 * @param {Function} props.onLoginSuccess - Function to handle successful login.
 * @param {string} [props.message] - Optional message to display in the popup.
 * @returns {React.ReactElement} An authentication popup element.
 */
function AuthPopup({ mode, onClose, onLoginSuccess, message }) {
  const [currentStep, setCurrentStep] = useState(mode);
  const [showTerms, setShowTerms] = useState(false);
  const [registrationStep, setRegistrationStep] = useState(1);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [acceptMarketing, setAcceptMarketing] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationSent, setVerificationSent] = useState(false);
  
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';

    const preventOverscroll = (e) => {
      const element = e.target;
      const scrollTop = element.scrollTop;
      const scrollHeight = element.scrollHeight;
      const height = element.clientHeight;
      const delta = e.deltaY;
      const isAtTop = scrollTop === 0;
      const isAtBottom = scrollHeight - scrollTop === height;

      if ((isAtTop && delta < 0) || (isAtBottom && delta > 0)) {
        e.preventDefault();
      }
    };

    const authPopup = document.querySelector('.auth-popup');
    if (authPopup) {
      authPopup.addEventListener('wheel', preventOverscroll, { passive: false });
    }

    return () => {
      document.body.style.overflow = originalStyle;
      if (authPopup) {
        authPopup.removeEventListener('wheel', preventOverscroll);
      }
    };
  }, []);

  const handleBack = () => {
    if (showTerms) {
      setShowTerms(false);
    } else if (currentStep === 'register') {
      if (registrationStep > 1) {
        setRegistrationStep(prevStep => prevStep - 1);
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  };

  const handleShowTerms = () => {
    setShowTerms(true);
  };

  const handleHideTerms = () => {
    setShowTerms(false);
  };

  const handleRegisterSuccess = (userData) => {
    onLoginSuccess(userData);
    onClose();
  };

  const getHeaderTitle = () => {
    if (currentStep === 'register') return "Create an Account";
    if (currentStep === 'login') return "Sign In";
    if (showTerms) return "Terms and Conditions";
    return "Authentication";
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('auth-popup-overlay')) {
      onClose();
    }
  };

  const switchMode = () => {
    setCurrentStep(currentStep === 'login' ? 'register' : 'login');
  };

  return (
    <div className="auth-popup-overlay" onClick={handleOverlayClick}>
      <div className="auth-popup">
        <BackButton onClick={handleBack} className="auth-popup-back-button" />
        <div className="auth-popup-content">
          <header className="auth-popup-header">
            <h1>{getHeaderTitle()}</h1>
          </header>

          {message && <p className="auth-popup-message">{message}</p>}

          <div className="auth-form-container">
            {showTerms ? (
              <TermsAndConditions onBack={handleHideTerms} fromRegistration={currentStep === 'register'} />
            ) : currentStep === 'register' ? (
              <Register
                onRegisterSuccess={handleRegisterSuccess}
                onRegistrationComplete={onClose}
                onShowTerms={handleShowTerms}
                onSwitchToLogin={switchMode}
                step={registrationStep}
                setStep={setRegistrationStep}
                name={name}
                setName={setName}
                email={email}
                setEmail={setEmail}
                confirmEmail={confirmEmail}
                setConfirmEmail={setConfirmEmail}
                birthMonth={birthMonth}
                setBirthMonth={setBirthMonth}
                birthDay={birthDay}
                setBirthDay={setBirthDay}
                birthYear={birthYear}
                setBirthYear={setBirthYear}
                password={password}
                setPassword={setPassword}
                repeatPassword={repeatPassword}
                setRepeatPassword={setRepeatPassword}
                acceptMarketing={acceptMarketing}
                setAcceptMarketing={setAcceptMarketing}
                verificationCode={verificationCode}
                setVerificationCode={setVerificationCode}
                verificationSent={verificationSent}
                setVerificationSent={setVerificationSent}
              />
            ) : currentStep === 'login' ? (
              <Login onLoginSuccess={onLoginSuccess} onSwitchToRegister={switchMode} />
            ) : null}
          </div>

          <div className="auth-switch">
            {currentStep === 'login' ? (
              <p>Don't have an account? <button onClick={switchMode}>Register</button></p>
            ) : (
              <p>Already have an account? <button onClick={switchMode}>Login</button></p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthPopup;
