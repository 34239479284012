import React, { useState, useEffect, useCallback } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import api from '../../api';
import './EventListPage.css';
import Header from '../Header/Header';
import EventFilter from './EventFilter';

// Add this function at the top of the file, outside of the component
const formatDateTime = (dateTimeString) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };
  return new Date(dateTimeString).toLocaleString('en-US', options);
};

function EventListPage() {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [featuredEvent, setFeaturedEvent] = useState(null);
  const { user } = useOutletContext();
  const [currentPage, setCurrentPage] = useState({ upcoming: 1, past: 1 });
  const [eventsPerPage, setEventsPerPage] = useState(3);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        // Fetch all events
        const allEventsResponse = await api.getAllEvents();
        if (Array.isArray(allEventsResponse.data)) {
          const featured = allEventsResponse.data.find(event => event.isFeatured);
          setFeaturedEvent(featured || null);

          // Separate upcoming/ongoing and past events
          const upcoming = allEventsResponse.data.filter(event => ['upcoming', 'ongoing'].includes(event.status));
          const past = allEventsResponse.data.filter(event => event.status === 'past');

          setUpcomingEvents(upcoming);
          setPastEvents(past);
        } else {
          console.error('Unexpected response format:', allEventsResponse.data);
          setUpcomingEvents([]);
          setPastEvents([]);
        }
      } catch (error) {
        console.error('Error fetching events:', error);
        setUpcomingEvents([]);
        setPastEvents([]);
      }
    };
    fetchEvents();
    fetchCities();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 767) {
        setEventsPerPage(6);
      } else if (window.innerWidth >= 520 && window.innerWidth <= 767) {
        setEventsPerPage(4);
      } else {
        setEventsPerPage(3);
      }
    };

    handleResize(); // Call once to set initial state
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getMaxPageNumber = useCallback((totalEvents, perPage) => {
    return Math.max(1, Math.ceil(totalEvents / perPage));
  }, []);

  useEffect(() => {
    const maxUpcomingPage = getMaxPageNumber(upcomingEvents.length, eventsPerPage);
    const maxPastPage = getMaxPageNumber(pastEvents.length, eventsPerPage);

    setCurrentPage(prev => ({
      upcoming: Math.min(prev.upcoming, maxUpcomingPage),
      past: Math.min(prev.past, maxPastPage)
    }));
  }, [upcomingEvents, pastEvents, eventsPerPage, getMaxPageNumber]);

  // Calculate the events for the current page
  const getCurrentEvents = (events, page) => {
    const indexOfLastEvent = page * eventsPerPage;
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    return events.slice(indexOfFirstEvent, indexOfLastEvent);
  };

  // Change page
  const paginate = (type, pageNumber) => {
    const maxPageNumber = getMaxPageNumber(type === 'upcoming' ? upcomingEvents.length : pastEvents.length, eventsPerPage);
    setCurrentPage(prev => ({
      ...prev,
      [type]: Math.min(pageNumber, maxPageNumber)
    }));
  };

  const fetchCities = async () => {
    try {
      const response = await api.getAllCities();
      setCities(Array.isArray(response.data) ? response.data : []); // Ensure cities is an array
    } catch (error) {
      console.error('Error fetching cities:', error);
      setCities([]); // Set cities to an empty array on error
    }
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  // Filter only upcoming events based on selected city
  const filteredUpcomingEvents = selectedCity
    ? upcomingEvents.filter(event => event.city === selectedCity)
    : upcomingEvents;

  const renderEventList = (events, type) => (
    <div className="events-grid">
      {events.length > 0 ? (
        getCurrentEvents(events, currentPage[type]).map(event => (
          <Link key={event._id} to={`/events/${event.slug}`} className="event-card">
            <img src={event.images[0]} alt={event.title} className="event-image" />
            <div className="event-content">
              <h2 className="event-title">{event.title}</h2>
              <p className="event-description">{event.shortDescription}</p>
              <div className="event-details">
                <div className="event-date">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                    <line x1="16" y1="2" x2="16" y2="6"></line>
                    <line x1="8" y1="2" x2="8" y2="6"></line>
                    <line x1="3" y1="10" x2="21" y2="10"></line>
                  </svg>
                  <span>{formatDateTime(event.dateTime)}</span>
                </div>
                <div className="event-location">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                    <circle cx="12" cy="10" r="3"></circle>
                  </svg>
                  <span>{event.location}</span>
                </div>
              </div>
              <button className="event-button">Find Out More</button>
            </div>
          </Link>
        ))
      ) : (
        <p>No events found for the selected city.</p>
      )}
    </div>
  );

  const renderPagination = (type, events) => (
    <div className="pagination">
      {Array.from({ length: getMaxPageNumber(events.length, eventsPerPage) }, (_, i) => (
        <button
          key={i}
          onClick={() => paginate(type, i + 1)}
          className={`page-button ${currentPage[type] === i + 1 ? 'active' : ''}`}
        >
          {i + 1}
        </button>
      ))}
    </div>
  );

  return (
    <div className="event-list-page">
      <Header user={user} />
      <main className="main-content">
        <section className="hero-section">
          <div className="container">
            <div className="hero-content">
              <h1 className="hero-title">Discover Extraordinary Events with Small Potatoes</h1>
              <p className="hero-description">
                Small Potatoes is your premier event platform, connecting you with unforgettable experiences. 
                From intimate gatherings to grand celebrations, we curate a diverse range of events to suit every taste. 
                Explore, book, and create memories with ease through our user-friendly platform.
              </p>
              <p className="hero-cta">
                Join our community of event enthusiasts and let Small Potatoes be your guide to the best experiences in town!
              </p>
            </div>
            {featuredEvent && (
              <div className="featured-event">
                <Link to={`/events/${featuredEvent.slug}`} className="featured-event-link">
                  <img src={featuredEvent.images[0]} alt={featuredEvent.title} className="featured-event-image" />
                  <div className="featured-event-content">
                    <h2 className="featured-event-title">{featuredEvent.title}</h2>
                    <p className="featured-event-description">{featuredEvent.shortDescription}</p>
                    <div className="event-details">
                      <div className="event-date">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
                          <line x1="16" y1="2" x2="16" y2="6"></line>
                          <line x1="8" y1="2" x2="8" y2="6"></line>
                          <line x1="3" y1="10" x2="21" y2="10"></line>
                        </svg>
                        <span>{new Date(featuredEvent.dateTime).toLocaleDateString()}</span>
                      </div>
                      <div className="event-location">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                          <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                        <span>{featuredEvent.location}</span>
                      </div>
                    </div>
                    <button className="event-button">Find Out More</button>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </section>
        <section className="events-section">
          <div className="container">
            <h2 className="section-title">Upcoming Events</h2>
            <p className="section-description">Upcoming events with Small Potatoes!</p>
            <EventFilter
              cities={cities}
              selectedCity={selectedCity}
              onCityChange={handleCityChange}
              label="Filter upcoming events by city"
            />
            {renderEventList(filteredUpcomingEvents, 'upcoming')}
            {filteredUpcomingEvents.length > 0 && renderPagination('upcoming', filteredUpcomingEvents)}
          </div>
        </section>
        {pastEvents.length > 0 && (
          <section className="past-events-section">
            <div className="container">
              <h2 className="section-title">Past Events</h2>
              <p className="section-description">Relive the excitement of past events with Small Potatoes!</p>
              {renderEventList(pastEvents, 'past')}
              {renderPagination('past', pastEvents)}
            </div>
          </section>
        )}
      </main>
    </div>
  );
}

export default EventListPage;
