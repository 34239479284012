import React, { useState, useEffect } from 'react';
import api from '../../api';
import './Login.css';

/**
 * Represents the login component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onLoginSuccess - Callback function to handle successful login.
 * @param {Function} props.onSwitchToRegister - Callback function to switch to registration view.
 * @returns {React.ReactElement} The login form.
 */
function Login({ onLoginSuccess, onSwitchToRegister }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [resetMessage, setResetMessage] = useState('');

  useEffect(() => {
    const handleFocus = () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    };

    const handleBlur = () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      }, 100);
    };

    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.addEventListener('focus', handleFocus);
      input.addEventListener('blur', handleBlur);
    });

    return () => {
      inputs.forEach(input => {
        input.removeEventListener('focus', handleFocus);
        input.removeEventListener('blur', handleBlur);
      });
    };
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await api.login(email, password, rememberMe);
      if (response.data && response.data.user) {
        onLoginSuccess(response.data.user);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError(err.response?.data?.message || 'An error occurred during login');
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError('Please enter your email address');
      return;
    }
    try {
      await api.requestPasswordReset(email);
      setResetMessage('Password reset link sent to your email');
      setError('');
    } catch (err) {
      console.error('Password reset error:', err);
      setError(err.response?.data?.message || 'An error occurred while requesting password reset');
    }
  };

  return (
    <div className="login-container auth-form-container">
      {error && <p className="error-message">{error}</p>}
      {resetMessage && <p className="success-message">{resetMessage}</p>}
      <form className="login-form auth-form" onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="remember-me">
          <input
            type="checkbox"
            id="remember-me"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label htmlFor="remember-me">Remember me</label>
        </div>
        <button type="submit" className="login-button">Sign In</button>
      </form>
      <button onClick={handleForgotPassword} className="forgot-password-button">Forgot Password?</button>
    </div>
  );
}

export default Login;