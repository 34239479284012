import React from 'react';
import placeholderImage from '../../../images/placeholder.webp';
import placeholderLogo from '../../../images/mcdonalds-logo.png';
import crownSvg from '../../../images/crown.svg';
import './VoucherCard.css';
import { useNavigate } from 'react-router-dom';

const VoucherCard = ({ voucher, onClick, ribbonType, redirectToHome = false }) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    if (redirectToHome) {
      navigate('/home');
    } else {
      onClick(event);
    }
  };

  return (
    <div className="voucher-card" onClick={handleClick}>
      <div className="voucher-image">
        <img 
          src={voucher.backgroundImageUrl || placeholderImage} 
          alt="Voucher background" 
        />
        <div className="company-logo">
          <img 
            src={voucher.company && voucher.company.logoUrl ? voucher.company.logoUrl : placeholderLogo} 
            alt={`${voucher.company ? voucher.company.name : 'Company'} logo`} 
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = placeholderLogo;
            }}
          />
        </div>
      </div>
      <div className="voucher-content">
        <div>
          <p className="company-description">{voucher.company.shortDescription}</p>
          <p className="voucher-description">{voucher.description}</p>
        </div>
        <p className="details">Click for more details</p>
      </div>
      {ribbonType && (
        <div className={`voucher-ribbon ${ribbonType}`}>
          {ribbonType === 'vip' && <img src={crownSvg} alt="VIP Crown" className="vip-crown" />}
          <span>{ribbonType.toUpperCase()}</span>
        </div>
      )}
    </div>
  );
};

export default VoucherCard;
