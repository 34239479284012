import React, { useState, useEffect } from 'react';
import api from '../../../api';

const CityManagement = ({ onCityAdded }) => {
  const [newCity, setNewCity] = useState('');

  const handleAddCity = async () => {
    if (newCity.trim()) {
      try {
        const response = await api.createCity({ name: newCity.trim() });
        if (response.status === 201) {
          setNewCity('');
          if (onCityAdded) {
            onCityAdded(response.data);
          }
        } else if (response.status === 409) {
          alert(`The city "${newCity}" already exists.`);
        }
      } catch (error) {
        console.error('Error adding new city:', error);
        if (error.response && error.response.status === 409) {
          alert(`The city "${newCity}" already exists.`);
        } else {
          alert('An error occurred while adding the city. Please try again.');
        }
      }
    }
  };

  return (
    <div className="city-management">
      <h4>City Management</h4>
      <p>If the city you're looking for doesn't exist, add it here:</p>
      <div className="form-group">
        <input
          type="text"
          value={newCity}
          onChange={(e) => setNewCity(e.target.value)}
          placeholder="Enter new city name"
        />
        <button type="button" onClick={handleAddCity}>Add City</button>
      </div>
    </div>
  );
};

export default CityManagement;
