import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './app';

/**
 * Entry point of the React application.
 * Renders the main App component into the DOM.
 */
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <App />
);
