import React, { useState, useEffect, useRef } from 'react';
import api from '../../../api';
import LoadingBar from '../../Ui/LoadingBar/LoadingBar';
import { compressAndConvertToWebP } from '../../../utils/imageprocessing';
import VoucherCard from '../../HomePage/VoucherCard/VoucherCard';
import VoucherDisplay from '../../VoucherDisplay/VoucherDisplay';
import './VoucherManagement.css';

function VoucherManagement({ cards, companies, updateCards }) {
    const [vouchers, setVouchers] = useState([]);
    const [newVoucher, setNewVoucher] = useState({
        code: '',
        description: '',
        redeemableAt: '',
        expiryDate: '',
        cardId: '',
        companyId: '',
        termsAndConditions: ''
    });
    const [editingVoucher, setEditingVoucher] = useState(null);
    const [voucherBackground, setVoucherBackground] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [error, setError] = useState('');
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [uploadError, setUploadError] = useState('');
    const fileInputRef = useRef(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [previewVoucher, setPreviewVoucher] = useState({
        code: '',
        description: '',
        redeemableAt: '',
        expiryDate: '',
        backgroundImageUrl: '',
        company: { name: '', logoUrl: '', shortDescription: '' }
    });
    const [showVoucherDisplay, setShowVoucherDisplay] = useState(false);
    const [selectedCard, setSelectedCard] = useState('all');
    const [filteredVouchers, setFilteredVouchers] = useState([]);

    useEffect(() => {
        fetchVouchers();
    }, []);

    useEffect(() => {
        if (selectedCard === 'all') {
            setFilteredVouchers(vouchers);
        } else {
            setFilteredVouchers(vouchers.filter(voucher => voucher.card === selectedCard));
        }
    }, [selectedCard, vouchers]);

    useEffect(() => {
        // Update preview voucher when newVoucher changes
        const selectedCompany = companies.find(company => company._id === newVoucher.companyId);
        setPreviewVoucher({
            ...newVoucher,
            backgroundImageUrl: previewImage,
            company: selectedCompany ? {
                name: selectedCompany.name,
                logoUrl: selectedCompany.logoUrl,
                shortDescription: selectedCompany.shortDescription || ''
            } : { name: '', logoUrl: '', shortDescription: '' }
        });
    }, [newVoucher, previewImage, companies]);

    const fetchVouchers = async () => {
        try {
            const response = await api.getAllVouchers();
            console.log('Vouchers:', response.data);
            setVouchers(response.data);
        } catch (error) {
            console.error('Error fetching vouchers:', error);
        }
    };

    const handleCreateVoucher = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('code', newVoucher.code || '');
            formData.append('description', newVoucher.description || '');
            formData.append('redeemableAt', newVoucher.redeemableAt || '');
            formData.append('expiryDate', newVoucher.expiryDate || '');
            formData.append('cardId', newVoucher.cardId || '');
            formData.append('companyId', newVoucher.companyId || '');
            formData.append('termsAndConditions', newVoucher.termsAndConditions || '');
            if (voucherBackground instanceof File) {
                formData.append('background', voucherBackground);
            }

            // Validate required fields
            if (!newVoucher.code || !newVoucher.description || !newVoucher.redeemableAt ||
                !newVoucher.expiryDate || !newVoucher.cardId || !newVoucher.companyId) {
                setError('Please fill in all required fields');
                return;
            }

            const response = await api.createVoucher(formData);
            setVouchers([...vouchers, response.data]);
            await updateCards();
            setSuccessMessage('Voucher created successfully!');
            setTimeout(() => setSuccessMessage(''), 3000);
            resetForm();
        } catch (error) {
            console.error('Error creating voucher:', error.response ? error.response.data : error);
            setError('Failed to create voucher. Please try again.');
        }
    };

    const handleUpdateVoucher = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('code', newVoucher.code || '');
            formData.append('description', newVoucher.description || '');
            formData.append('redeemableAt', newVoucher.redeemableAt || '');
            formData.append('expiryDate', newVoucher.expiryDate || '');
            formData.append('cardId', newVoucher.cardId || '');
            formData.append('companyId', newVoucher.companyId || '');
            formData.append('termsAndConditions', newVoucher.termsAndConditions || '');

            // Handle image update
            if (voucherBackground instanceof File) {
                formData.append('background', voucherBackground);
            } else if (voucherBackground === null && editingVoucher.backgroundImageUrl) {
                //no way to remove background image currently
            }

            const response = await api.updateVoucher(editingVoucher._id, formData);
            setVouchers(vouchers.map(voucher => voucher._id === editingVoucher._id ? response.data : voucher));
            await updateCards();
            setSuccessMessage('Voucher updated successfully!');
            setTimeout(() => setSuccessMessage(''), 3000);
            resetForm();
        } catch (error) {
            console.error('Error updating voucher:', error.response ? error.response.data : error);
            setError('Failed to update voucher. Please try again.');
        }
    };

    const handleEditVoucher = (voucher) => {
        setEditingVoucher(voucher);
        setNewVoucher({
            code: voucher.code,
            description: voucher.description,
            redeemableAt: voucher.redeemableAt,
            expiryDate: voucher.expiryDate.split('T')[0],
            cardId: voucher.card,
            companyId: voucher.company._id,
            termsAndConditions: voucher.termsAndConditions
        });
        setVoucherBackground(null);
        setPreviewImage(voucher.backgroundImageUrl);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const processedFile = await compressAndConvertToWebP(file);
                setVoucherBackground(processedFile);
                setPreviewImage(URL.createObjectURL(processedFile));
            } catch (error) {
                console.error('Error processing voucher background:', error);
                setError('Error processing image. Please try again.');
            }
        }
    };

    const resetForm = () => {
        setEditingVoucher(null);
        setNewVoucher({
            code: '',
            description: '',
            redeemableAt: '',
            expiryDate: '',
            cardId: '',
            companyId: '',
            termsAndConditions: ''
        });
        setVoucherBackground(null);
        setPreviewImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDeleteVoucher = async (voucherId) => {
        if (window.confirm('Are you sure you want to delete this voucher? This action cannot be undone.')) {
            try {
                await api.deleteVoucher(voucherId);
                setVouchers(vouchers.filter(voucher => voucher._id !== voucherId));
                await updateCards();
            } catch (error) {
                console.error('Error deleting voucher:', error);
                setError('Failed to delete voucher. Please try again.');
            }
        }
    };

    const toggleVoucherDisplay = () => {
        setShowVoucherDisplay(!showVoucherDisplay);
    };

    const handleCardSelect = (e) => {
        setSelectedCard(e.target.value);
    };

    // Add a function to remove the background image
    const handleRemoveBackground = () => {
        setVoucherBackground(null);
        setPreviewImage(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <div className="voucher-management">
            <div className="voucher-form-container">
                <h2>{editingVoucher ? 'Edit Voucher' : 'Create New Voucher'}</h2>
                <div className="voucher-form-preview">
                    <form onSubmit={editingVoucher ? handleUpdateVoucher : handleCreateVoucher} className="voucher-form">
                        <div className="form-group">
                            <label htmlFor="code">Voucher Code</label>
                            <input
                                id="code"
                                type="text"
                                value={newVoucher.code}
                                onChange={(e) => setNewVoucher({ ...newVoucher, code: e.target.value })}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <textarea
                                id="description"
                                value={newVoucher.description}
                                onChange={(e) => setNewVoucher({ ...newVoucher, description: e.target.value })}
                                required
                            />
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="redeemableAt">Redeemable At</label>
                                <input
                                    id="redeemableAt"
                                    type="text"
                                    value={newVoucher.redeemableAt}
                                    onChange={(e) => setNewVoucher({ ...newVoucher, redeemableAt: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="expiryDate">Expiry Date</label>
                                <input
                                    id="expiryDate"
                                    type="date"
                                    value={newVoucher.expiryDate}
                                    onChange={(e) => setNewVoucher({ ...newVoucher, expiryDate: e.target.value })}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label htmlFor="cardId">Card</label>
                                <select
                                    id="cardId"
                                    value={newVoucher.cardId}
                                    onChange={(e) => setNewVoucher({ ...newVoucher, cardId: e.target.value })}
                                    required
                                >
                                    <option value="">Select a card</option>
                                    {Array.isArray(cards) && cards.map(card => (
                                        <option key={card._id} value={card._id}>{card.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="companyId">Company</label>
                                <select
                                    id="companyId"
                                    value={newVoucher.companyId}
                                    onChange={(e) => setNewVoucher({ ...newVoucher, companyId: e.target.value })}
                                    required
                                >
                                    <option value="">Select a company</option>
                                    {Array.isArray(companies) && companies.map(company => (
                                        <option key={company._id} value={company._id}>{company.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="termsAndConditions">Terms and Conditions</label>
                            <textarea
                                id="termsAndConditions"
                                value={newVoucher.termsAndConditions}
                                onChange={(e) => setNewVoucher({ ...newVoucher, termsAndConditions: e.target.value })}
                                rows={5}
                                placeholder="Enter terms and conditions. Use line breaks for formatting."
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="background">Background Image</label>
                            <input
                                id="background"
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                            />
                            {(previewImage || (editingVoucher && editingVoucher.backgroundImageUrl)) && (
                                <button type="button" onClick={handleRemoveBackground} className="btn-secondary">
                                    Remove Background
                                </button>
                            )}
                        </div>
                        {previewImage && (
                            <div className="image-preview">
                                <img src={previewImage} alt="Voucher Background Preview" />
                            </div>
                        )}
                        {loadingProgress > 0 && <LoadingBar progress={loadingProgress} />}
                        {uploadError && <p className="error-message">{uploadError}</p>}
                        <div className="form-actions">
                            <button type="submit" className="btn-primary">{editingVoucher ? 'Update Voucher' : 'Create Voucher'}</button>
                            {editingVoucher && (
                                <button type="button" onClick={resetForm} className="btn-secondary">Cancel Edit</button>
                            )}
                        </div>
                    </form>
                    <div className="voucher-preview">
                        <h3>Card Preview</h3>
                        <VoucherCard voucher={previewVoucher} />
                        <button onClick={toggleVoucherDisplay} className="btn-secondary">
                            {showVoucherDisplay ? 'Hide Voucher Display' : 'Show Voucher Display'}
                        </button>
                        {showVoucherDisplay && (
                            <div className="voucher-display-preview">
                                <VoucherDisplay
                                    voucher={previewVoucher}
                                    onClose={toggleVoucherDisplay}
                                    isPreview={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {successMessage && <div className="success-message">{successMessage}</div>}
            <div className="voucher-list">
                <h2>Existing Vouchers</h2>
                <div className="card-selector">
                    <label htmlFor="cardSelect">Filter by Card:</label>
                    <select id="cardSelect" value={selectedCard} onChange={handleCardSelect}>
                        <option value="all">All Cards</option>
                        {cards.map(card => (
                            <option key={card._id} value={card._id}>{card.name}</option>
                        ))}
                    </select>
                </div>
                <div className="voucher-grid">
                    {filteredVouchers.map(voucher => (
                        <div key={voucher._id} className="voucher-item">
                            <VoucherCard voucher={voucher} />
                            <div className="voucher-actions">
                                <button onClick={() => handleEditVoucher(voucher)} className="btn-edit">Edit</button>
                                <button onClick={() => handleDeleteVoucher(voucher._id)} className="btn-delete">Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default VoucherManagement;