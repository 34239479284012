import React from 'react';
import './TicketCard.css';

const getStatusColor = (status, isExpired) => {
  if (isExpired) return 'status-expired';
  if (!status) return 'status-default';
  
  switch (status.toLowerCase()) {
    case 'active':
      return 'status-active';
    case 'used':
      return 'status-used';
    case 'expired':
      return 'status-expired';
    default:
      return 'status-default';
  }
};

/**
 * Represents a ticket card component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.ticket - The ticket object containing event details.
 * @param {Function} props.onClick - Function to handle click events on the ticket card.
 * @param {Boolean} props.isExpired - Optional flag to indicate if the ticket is expired.
 * @returns {React.ReactElement} A ticket card element.
 */
const TicketCard = ({ ticket, onClick, isExpired = false }) => {
  const status = isExpired ? 'Expired' : (ticket.status || 'Unknown');
  
  return (
    <div className={`ticket-card ${isExpired ? 'expired' : ''}`} onClick={() => onClick(ticket)}>
      <div className="ticket-header">
        <h3 className="ticket-title">{ticket.eventTitle}</h3>
      </div>
      <div className="ticket-content">
        <p className="ticket-info">Date: {new Date(ticket.eventDate).toLocaleDateString()}</p>
        <p className="ticket-info">Type: {ticket.ticketType}</p>
      </div>
      <div className="ticket-footer">
        <span className="ticket-type-badge">{ticket.ticketTypeBadge}</span>
        <span className={`ticket-status-badge ${getStatusColor(status, isExpired)}`}>
          {status}
        </span>
      </div>
    </div>
  );
};

export default TicketCard;