import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import './PasswordReset.css';
import BackButton from '../Ui/BackButton';

/**
 * Represents the password reset component.
 *
 * @component
 * @returns {React.ReactElement} The password reset form.
 */
const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isResetMode, setIsResetMode] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const passwordToggleRef = useRef(null);

  useEffect(() => {
    if (token) {
      setIsResetMode(true);
    }
  }, [token]);

  useEffect(() => {
    const initialLoadingElement = document.querySelector('.initial-loading');
    if (initialLoadingElement) {
      initialLoadingElement.remove();
    }
  }, []); 

  const handleRequestReset = async (e) => {
    e.preventDefault();
    try {
      await api.requestPasswordReset(email);
      setMessage('Password reset link sent to your email.');
    } catch (error) {
      setMessage('Error requesting password reset. Please try again.');
    }
  };

  const validatePassword = (password) => {
    return password.length >= 8 &&
      /[A-Z]/.test(password) &&
      /[a-z]/.test(password) &&
      /[0-9]/.test(password);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!validatePassword(newPassword)) {
      setMessage('Password does not meet the required criteria.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    try {
      await api.resetPassword(token, newPassword);
      setMessage('Password reset successful. You can now log in with your new password.');
      setTimeout(() => navigate('/landing?openLogin=true', { replace: true }), 3000);
    } catch (error) {
      setMessage('Error resetting password. Please try again.');
    }
  };

  const handleClose = () => {
    navigate('/landing?openLogin=true', { replace: true });
  };

  return (
    <div className="password-reset-container">
      <div className="password-reset-form">
        <BackButton onClick={handleClose} className="password-reset-close-button" />
        <h2>{isResetMode ? 'Reset Your Password' : 'Request Password Reset'}</h2>
        {message && <p className="message">{message}</p>}
        {!isResetMode ? (
          <form onSubmit={handleRequestReset}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit">Request Reset</button>
          </form>
        ) : (
          <form onSubmit={handleResetPassword}>
            <div className={`password-rules ${isPasswordFocused ? 'visible' : ''}`}>
              <p>Your password must:</p>
              <ul>
                <li className={newPassword.length >= 8 ? 'met' : ''}>Be at least 8 characters long</li>
                <li className={/[A-Z]/.test(newPassword) ? 'met' : ''}>Include at least one uppercase letter</li>
                <li className={/[a-z]/.test(newPassword) ? 'met' : ''}>Include at least one lowercase letter</li>
                <li className={/[0-9]/.test(newPassword) ? 'met' : ''}>Include at least one number</li>
              </ul>
            </div>
            <div className="form-group">
              <label htmlFor="new-password">New Password:</label>
              <div className="password-input-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  id="new-password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={(e) => {
                    if (!e.relatedTarget || !e.relatedTarget.classList.contains('password-toggle')) {
                      setIsPasswordFocused(false);
                    }
                  }}
                  required
                />
                <button
                  type="button"
                  className="password-toggle"
                  ref={passwordToggleRef}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                    document.getElementById('new-password').focus();
                  }}
                >
                  {showPassword ? "👁️" : "👁️‍🗨️"}
                </button>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">Confirm Password:</label>
              <div className="password-input-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
            </div>
            <button type="submit">Reset Password</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default PasswordReset;