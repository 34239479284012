import React from 'react';
import './TicketTypeForm.css';

const TicketTypeForm = ({ ticketTypes, handleInputChange, addTicketType, removeTicketType }) => {
  return (
    <div className="ticket-type-form">
      <h4>Ticket Types</h4>
      {ticketTypes.map((ticketType, index) => (
        <div key={index} className="ticket-type-card">
          <div className="card-header">
            <h5>{ticketType.title || "New Ticket Type"}</h5>
            <button type="button" onClick={() => removeTicketType(index)} className="remove-ticket-type">Remove</button>
          </div>
          <div className="card-content">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor={`ticketType-title-${index}`}>Title</label>
                <input
                  id={`ticketType-title-${index}`}
                  type="text"
                  name={`ticketTypes.title`}
                  value={ticketType.title}
                  onChange={(e) => handleInputChange(e, index)}
                  placeholder="e.g., Early Bird, VIP, General Admission"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor={`ticketType-badge-${index}`}>Badge</label>
                <input
                  id={`ticketType-badge-${index}`}
                  type="text"
                  name={`ticketTypes.badge`}
                  value={ticketType.badge}
                  onChange={(e) => handleInputChange(e, index)}
                  placeholder="e.g., EARLY, VIP, GA"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor={`ticketType-description-${index}`}>Description</label>
              <textarea
                id={`ticketType-description-${index}`}
                name={`ticketTypes.description`}
                value={ticketType.description}
                onChange={(e) => handleInputChange(e, index)}
                placeholder="Brief description of ticket benefits"
                required
                rows="3"
              />
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor={`ticketType-price-${index}`}>Price (£)</label>
                <input
                  id={`ticketType-price-${index}`}
                  type="number"
                  name={`ticketTypes.price`}
                  value={ticketType.price}
                  onChange={(e) => handleInputChange(e, index)}
                  placeholder="0.00"
                  step="0.01"
                  min="0"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor={`ticketType-quantity-${index}`}>Total Quantity</label>
                <input
                  id={`ticketType-quantity-${index}`}
                  type="number"
                  name={`ticketTypes.quantity`}
                  value={ticketType.quantity}
                  onChange={(e) => handleInputChange(e, index)}
                  placeholder="Total number of tickets"
                  min="0"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <button type="button" onClick={addTicketType} className="add-ticket-type">Add New Ticket Type</button>
    </div>
  );
};

export default TicketTypeForm;
