/**
 * Contains translations for different languages used in the application.
 *
 * @constant
 * @type {Object.<string, Object.<string, string>>}
 */
export const translations = {
    en: {
      profile: "Profile",
      settings: "Settings",
      login: "Login",
      logout: "Logout",
      noCards: "No cards available",
      vouchers: "Vouchers",
      tickets: "Tickets",
      noVouchers: "No vouchers available",
      redeem: "Redeem",
      adminDashboard: "Admin Dashboard",
      mobile: "Mobile",
      save: "Save",  
      yourCards: "Your Cards",
      moreVouchersComingSoon: "More vouchers coming soon",
      moreEventsComingSoon: "More events coming soon!",
      noTickets: "No tickets available",
      currencyConversions: "Currency conversions will be applied at checkout",
      nonRefundable: "Tickets are non-refundable",
      vouchersDescription: "These are vouchers for local Birmingham food, drinks, and activities. Click on them to show at checkout and enjoy!",
      trialPopupTitle: "Welcome to our Voucher Trial!",
      trialPopupDescription: "You've been granted access to our brand new Voucher system for a limited time. Explore and enjoy the benefits!",
      dismissButton: "Dismiss",
      cityfilter: "Filter upcoming events by city"
            // Add more translations here
    },
    zh: {
      profile: "个人资料",
      settings: "设置",
      login: "登录",
      logout: "登出",
      noCards: "没有可用的卡",
      vouchers: "优惠券",
      tickets: "票",
      noVouchers: "没有可用的优惠券",
      redeem: "兑换",
      adminDashboard: "管理员仪表板",
      mobile: "手机号码",
      save: "保存",
      yourCards: "你的卡片",
      moreVouchersComingSoon: "更多优惠券即将到来",
      moreEventsComingSoon: "更多活动即将推出！",
      noTickets: "没有可用的票",
      currencyConversions: "货币按实时汇率自动转换",
      nonRefundable: "所有购买门票不可退款",
      vouchersDescription: "这些是伯明翰的餐饮、娱乐优惠券，点击并在结账时出示即可享受优惠",
      trialPopupTitle: "欢迎使用我们的试用版！",
      trialPopupDescription: "您已获得访问我们新优惠券系统的权限。探索并享受这些优惠！",
      dismissButton: "关闭",
      cityfilter: "按城市过滤即将到来的活动"
            // Add more translations here
    }
  };