import React from 'react';
import './TicketTypeDisplay.css';

const TicketTypeDisplay = ({ ticketTypes, ticketCounts, eventId }) => {
  if (!ticketTypes || !Array.isArray(ticketTypes)) {
    return null;
  }

  return (
    <div className="ticket-types">
      <h5>Ticket Types:</h5>
      <div className="ticket-type-grid">
        {ticketTypes.map((type) => {
          // Safely access ticket counts
          const counts = ticketCounts?.[eventId] || {};
          
          return (
            <div key={type._id} className="ticket-type-item">
              <h6>{type.title}</h6>
              <p>Price: £{type.price}</p>
              <p>Quantity: {type.quantity}</p>
              <p>Sold: {type.sold || 0}</p>
              <p>Available: {type.quantity - (type.sold || 0)}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TicketTypeDisplay;
