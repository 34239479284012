import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LanguageProvider } from './Translations/LanguageContext';
import './app.css';
import './GlobalStyles.css';
import ErrorBoundary from './Catch/ErrorBoundary';
import routes from './Routes/routes';
import { fetchCSRFToken } from './api';

const router = createBrowserRouter(routes);


const fetchToken = async () => {
  console.log('Creating TOKEN');
  await fetchCSRFToken();
}

fetchToken();

/**
 * Main application component.
 * 
 * @component
 * @returns {React.ReactElement} The root application element wrapped with necessary providers.
 */
function App() {
 
  return (
    <ErrorBoundary>
      <LanguageProvider>
        <RouterProvider router={router} />
      </LanguageProvider>
    </ErrorBoundary>
  );
}

export default App;
