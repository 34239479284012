import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import AppContent, { ProtectedRoute } from '../AppContent/AppContent';
import HomePage from '../components/HomePage/HomePage';
import LandingPage from '../components/LandingPage/LandingPage';
import AuthPopup from '../components/AuthPopup/AuthPopup';
import AdminPage from '../components/Admin/AdminPage';
import SettingsPage from '../components/Settings/Settings';
import ContactUs from '../components/ContactUs/ContactUs';
import AboutUs from '../components/AboutUs/AboutUs';
import WorkWithUs from '../components/WorkWithUs/WorkWithUs';
import EventPage from '../components/Events/EventPage';
import EventListPage from '../components/Events/EventListPage';
import Checkout from '../components/Events/Purchase/Checkout/Checkout';
import Confirmation from '../components/Events/Purchase/Payment/Confirmation';
import PasswordReset from '../components/PasswordReset/PasswordReset';
import OrganizerRoute from './OrganizerRoute/OrganizerRoute';

// Lazy load the TicketVerification component
const TicketVerification = lazy(() => import('../components/TicketVerification/TicketVerification'));

/**
 * Defines the application's route configuration.
 *
 * @constant
 * @type {Array<Object>}
 */
const routes = [
  {
    path: '/',
    element: <AppContent />,
    children: [
      {
        index: true,   
        element: <LandingPage />,
      },
      {
        path: 'home',
        element: <ProtectedRoute redirectTo="/"><HomePage /></ProtectedRoute>,
      },
      {
        path: 'admin',
        element: (
          <ProtectedRoute redirectTo="/" adminOnly={true}>
            <AdminPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
      {
        path: 'contact',
        element: <ContactUs />,
      },
      {
        path: 'about',
        element: <AboutUs />,
      },
      {
        path: 'work-with-us',
        element: <WorkWithUs />,
      },
      {
        path: 'events',
        element: <EventListPage />,
      },
      {
        path: 'events/:id',
        element: <EventPage />,
      },
      {
        path: 'events/:slug',
        element: <EventPage />,
      },
      {
        path: 'checkout',
        element: <Checkout />,
      },
      {
        path: 'confirmation/:orderId',
        element: <Confirmation />,
      },
      {
        path: 'verify-ticket',
        element: (
          <OrganizerRoute>
            <Suspense fallback={<div>Loading QR Scanner...</div>}>
              <TicketVerification />
            </Suspense>
          </OrganizerRoute>
        ),
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: '/reset-password/:token',
    element: <PasswordReset />,
  }
];

export default routes;
