import React, { useState, useRef, useEffect } from 'react';
import './VoucherDisplay.css';
import { QRCodeSVG } from 'qrcode.react';  // Updated import
import DOMPurify from 'dompurify';

/**
 * Represents a voucher display component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.voucher - The voucher object containing discount and company details.
 * @param {Function} props.onClose - Function to handle closing the voucher display.
 * @param {Boolean} props.isPreview - Whether the voucher display is in preview mode or not.
 * @returns {React.ReactElement} A voucher display element with company logo, discount details, and terms.
 */
function VoucherDisplay({ voucher, onClose, isPreview = false }) {
  const [termsExpanded, setTermsExpanded] = useState(false);
  const termsContentRef = useRef(null);

  const toggleTerms = () => {
    setTermsExpanded(prevState => !prevState);
  };

  useEffect(() => {
    if (termsExpanded) {
      termsContentRef.current.style.maxHeight = `${termsContentRef.current.scrollHeight}px`;
      if (!isPreview) {
        setTimeout(() => {
          termsContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 120); // Adjust the timeout duration if needed
      }
    } else {
      termsContentRef.current.style.maxHeight = '0';
    }
  }, [termsExpanded, isPreview]);

  const getGoogleMapsUrl = (location) => {
    // Encode the location for use in a URL
    const encodedLocation = encodeURIComponent(location);
    return `https://www.google.com/maps/search/?api=1&query=${encodedLocation}`;
  };

  const websiteUrl = "https://yourwebsite.com"; // Replace with your actual website URL

  const renderTermsAndConditions = (text) => {
    if (!text) return <p>No specific terms and conditions provided for this voucher.</p>;
    
    return text.split('\n').map((paragraph, index) => (
      <p key={index}>{paragraph} </p>
    ));
  };

  return (
    <>
      {!isPreview && <div className="voucher-display-overlay" onClick={onClose}></div>}
      <div className={`voucher-display ${isPreview ? 'preview-mode' : ''}`} onClick={(e) => e.stopPropagation()}>
        <div className="voucher-display-header">     
          {!isPreview && <button className="voucher-display-close-button" onClick={onClose}>×</button>}
          <h2>My Voucher</h2>
        </div>
        <div className="voucher-display-content">
          <img 
            src={voucher.company.logoUrl} 
            alt={`${voucher.company.name} logo`} 
            className="voucher-display-logo" 
          />
          <h3 className="voucher-display-title">{voucher.company.name}</h3>
          <p className="voucher-display-company-description">{voucher.company.shortDescription}</p>
          <div className="voucher-display-description">{voucher.description}</div>
          <div className="voucher-display-redeemable">
            Redeemable at: 
            <a 
              href={getGoogleMapsUrl(voucher.redeemableAt)} 
              target="_blank" 
              rel="noopener noreferrer"
              className="voucher-display-map-link"
            >
              {voucher.redeemableAt}
            </a>
          </div>
          <div className="voucher-display-divider"></div>
          <div className="voucher-display-qr">
            <QRCodeSVG value={websiteUrl} size={150} />  {/* Updated QR code component */}
            <p className="voucher-display-share">Share with your friends!</p>
          </div>
          <p className={`voucher-display-terms ${termsExpanded ? 'expanded' : ''}`} onClick={toggleTerms}>
            Terms and conditions apply
          </p>
          <div ref={termsContentRef} className={`voucher-display-terms-content ${termsExpanded ? 'expanded' : ''}`}>
            <p>Expiry Date: {new Date(voucher.expiryDate).toLocaleDateString()}</p>
            <div className="terms-and-conditions">
              {renderTermsAndConditions(voucher.termsAndConditions)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VoucherDisplay;